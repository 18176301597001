import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ICommonPaymentData } from '../../interfaces';
import { LoggerService } from '../logger/logger.service';
import { EXPIRY_DAYS_FOR_AGENTID } from 'src/app/app.constants';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  agentId$ = new BehaviorSubject<string | null>(this.getAgentId());
  refId$ = new BehaviorSubject<string | null>(null);
  captureByLinkRefId$ = new BehaviorSubject<string | null>(null);
  cardData$ = new BehaviorSubject<ICommonPaymentData | null>(null);
  transferred$ = new BehaviorSubject<boolean | null>(null);
  termsAndConditionsRead$ = new BehaviorSubject<boolean>(false);
  isCardOnFileSelected$ = new BehaviorSubject<boolean>(false);
  ccaasMasking$ = new BehaviorSubject<boolean>(false);
  agentList$ = new BehaviorSubject<string[]>([]);

  originalCardData: ICommonPaymentData | null = null;

  constructor(private loggerSrv: LoggerService) {
    this.agentId$.next(this.getAgentId());
  }

  getAgentId(): string | null {
    const id = localStorage.getItem('agentIdWithPhone');

    if (id) {
      const parsedId = JSON.parse(id);
      if (parsedId.expiry > new Date().valueOf()) {
        // make use of the item if not expired
        return parsedId.value;
      } else {
        // remove expired agentId item if expired
        localStorage.removeItem('agentIdWithPhone');
        return null
      }
    }

    return null;
  }

  saveOriginalCardData(data: ICommonPaymentData | null): void {
    this.originalCardData = data;
  }

  useOriginalCardData(): void {
    this.cardData$.next(this.originalCardData);
  }

  redirectToApplication(url: string | null): void {
    if (!url || url === '') { this.loggerSrv.log('No redirect url available.'); return; }

    window.location.href = url;
  }

  setAgentIdWithExpiry(value: string | null): void {
    if (!value) { return; }

    const now = new Date();
    // expiry set for 30 days
    const item = {
      value: value,
      expiry: now.valueOf() + (EXPIRY_DAYS_FOR_AGENTID * 86400000),
    }

    localStorage.setItem('agentIdWithPhone', JSON.stringify(item));
    this.agentId$.next(value);
  }
}
