import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';

import { Observable, of } from "rxjs";

import { ICancelPayload, ISubmitPayment } from "../../interfaces";
import { ISendLinkPayload, ISendLinkResponse } from 'src/app/shared/components/link-transfer/link-transfer.interface';

import { ICardOnFile } from 'src/app/shared/components/card-on-file/card-on-file.interface';

import { DataService } from '../data/data.service';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class CreditCardService {

  paymentBasePath = `${environment.apiBasePath}commonPayment/`;

  constructor(private http: HttpClient, private dataSrv: DataService) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getCreditCardDetailsNew(refId: string): Observable<any> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.http.get<any>(`${this.paymentBasePath}getByReferenceId/${refId}?uniqId=${Math.floor(Math.random() * 1000) + 1}`);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getCardOnFileDetails(): Observable<any> {
    if (!this.dataSrv.cardData$.value?.contractNumber) {
      console.log('No Contract Number to fetch card on file data'); return of(false);
    }

    return this.http.get<ICardOnFile[] | null>(`${this.paymentBasePath}cards-on-file?contractId=${Math.trunc(Number(this.dataSrv.cardData$.value?.contractNumber))}`);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  submitPayment(payload: any): Observable<any> {
    let requestUrl = 'updatePaymentDetails';

    if (payload.paymentActionType) {
      requestUrl = (payload.paymentActionType != 'Customer Initiated') ? 'payment-info/' + payload.paymentActionType : 'payment-info';
    }

    return this.http.post<ISubmitPayment>(`${this.paymentBasePath}${requestUrl}`, payload);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cancelPayment(payload: ICancelPayload): Observable<any> {
    return this.http.put(`${this.paymentBasePath}cancelPayment`, payload);
  }

  captureBySMS(clickCount: number, payload: ISendLinkPayload): Observable<ISendLinkResponse> {
    if (clickCount > 1) {
      return this.captureBySMSThroughSalesforce(payload);
    }

    return this.captureBySMSThroughTwillio(payload);
  }

  captureBySMSThroughTwillio(payload: ISendLinkPayload): Observable<ISendLinkResponse> {
    return this.http.post<ISendLinkResponse>(`${this.paymentBasePath}sms/capture-link`, payload);
  }

  captureBySMSThroughSalesforce(payload: ISendLinkPayload): Observable<ISendLinkResponse> {
    return this.http.post<ISendLinkResponse>(`${this.paymentBasePath}sms/salesforce/capture-link`, payload);
  }

  captureByEmail(payload: ISendLinkPayload): Observable<ISendLinkResponse> {
    return this.http.post<ISendLinkResponse>(`${this.paymentBasePath}email/capture-link`, payload);
  }

  captureManually(payload: ISendLinkPayload): Observable<ISendLinkResponse> {
    return this.http.post<ISendLinkResponse>(`${this.paymentBasePath}agent/capture-link`, payload);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getAgentsListFromLifeRay(): Observable<any> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.http.get<any>(`${environment.webContentDomain}cinch-apis/-/webcontent/article/COMMON-PAYMENT-AGENTIDS?uniqId=${new Date().getTime()}`).pipe(
      tap((res) => {
        this.dataSrv.agentList$.next(res.agentids);
      })
    );
  }
}
